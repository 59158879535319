import React from 'react';
import "../styles/components/errorPage.sass";
import {StaticImage} from "gatsby-plugin-image";

const NotFoundPage = () => (
  <div className="errorPage">
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    <StaticImage src="../../assets/404/cat.png" alt=""/>
  </div>
);

export default NotFoundPage;
